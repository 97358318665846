import { canNavigate } from '@/plugins/acl/routeProtection'
import Vue from 'vue'
import VueRouter from 'vue-router'
import { modalEnquireBtn } from '@core/utils/prompt'
import store from '@/store'
import useJwt from '@/auth/jwt/useJwt'
import base from '@/router/base'

import dashboard from './dashboard'

Vue.use(VueRouter)
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

const routes = [
  // ? We are redirecting to different pages based on role.
  // NOTE: Role is just for UI purposes. ACL is based on abilities.
  {
    path: '/',
    redirect: to => {
      const userData = JSON.parse(localStorage.getItem('userData'))
      const userRole = userData && userData.role ? userData.role : null

      if (userData === '') {
        // 如果没有role则跳转到相关提示页面
        return { name: 'auth-login', query: to.query }
      }

      if (userRole === 'admin') return { name: 'my-task-list' }

      // 如果没有role则跳转到相关提示页面
      return { name: 'my-task-list', query: to.query }
    },
  },

  ...dashboard,
  ...base,
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
})

function judgeUpdate() {
  if (localStorage.getItem(useJwt.jwtConfig.storageVersionKeyName) !== useJwt.jwtConfig.version) {
    modalEnquireBtn('版本已更新', '请点击刷新按钮进行页面刷新！', '刷新').then(res => {
      if (res.isConfirmed === true) {
        /* 保存版本信息 */
        localStorage.setItem(useJwt.jwtConfig.storageVersionKeyName, useJwt.jwtConfig.version)

        // 刷新页面
        window.location.reload()
      }
    })
  }
}

function getRoute() {
  return new Promise(resolve => {
    useJwt.getRoute().then(response => {
      const role = response.data.data
      const routerData = []
      role.forEach(item => {
        const userRouter = {
          path: item.path,
          name: item.route,
          // eslint-disable-next-line global-require,import/no-dynamic-require
          component: res => require([`@/views${item.path}`], res),
          meta: item.meta,
        }
        routerData.push(userRouter)
      })

      return resolve(routerData)
    })
  })
}

// ? Router Before Each hook: https://router.vuejs.org/guide/advanced/navigation-guards.html
// eslint-disable-next-line consistent-return
router.beforeEach(async (to, _, next) => {
  const userData = localStorage.getItem('userData')
  const isLoggedIn = userData && localStorage.getItem('accessToken') && localStorage.getItem('userAbility')
  if (isLoggedIn) {
    // 判断版本
    judgeUpdate()

    // 使用vuex记录是否已经加载过路由表
    if (!store.state.app.menuRouteLoaded) {
      const routerData = await getRoute()

      // vue2 写法
      routerData.push({
        path: '*',
        redirect: 'error-404',
        hidden: true,
      })

      routerData.forEach(item => router.addRoute(item))

      // 保存加载状态
      store.commit('app/MENU_ROUTE_LOADED', true)
      store.commit('app/UPDATE_NAV_TREE', routerData)

      next({ ...to, replace: true })
    }
  }

  if (!canNavigate(to)) {
    // Redirect to login if not logged in
    // if (!isLoggedIn) return next({ name: 'auth-login', query: { marketplace: to.query.marketplace } })
    if (!isLoggedIn) return next({ name: 'auth-login' })

    // If logged in => not authorized
    // return next({ name: 'misc-not-authorized' })

    // return next({ name: 'misc-not-authorized' })
  }

  // Redirect if logged in
  if (to.meta.redirectIfLoggedIn && isLoggedIn) {
    next('/')
  }

  return next()
})

export default router
